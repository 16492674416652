<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/register`"
                title="register"
                :columns="columns"
                routerpath="/hr/addregister"
                :formOptions="formOptions"
                :newPage="true"
                :viewFields="viewFields"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :canView="canView"
              >
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'datetime'">
                    {{
                      props.row.datetime && props.row.datetime !== "0000-00-00"
                        ? moment(props.row.datetime).format("DD/MM/YYYY h:m A")
                        : ""
                    }}
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import moment from "moment";
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      moment,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Offer" : "Add Offer"}`,
        submitRouterPath: "/setup/register",
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/registerById`,
        url: `${baseApi}/offer`,
        gridForm: true,
        inputFields: [],
      },
      viewFields: [
        {
          label: "Date Time",
          field: "datetime",
          type: "datetime",
          class: "col-md-4",
        },
        {
          label: "Name",
          field: "name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Mobile",
          field: "mobile",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address / City",
          field: "address",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "No. Of Person",
          field: "noofperson",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Purpose",
          field: "purpose",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Whom To Meet",
          field: "whomtomeet",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Refrence",
          field: "refrence",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Time Out",
          field: "timeout",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Other Details",
          field: "otherdetails",
          type: "text",
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "Date Time",
          field: "datetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Mobile Number",
          },
        },
        {
          label: "Address / City",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Address / City",
          },
        },
        {
          label: "Purpose",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Purpose",
          },
        },
        {
          label: "Whom To Meet",
          field: "whomtomeet",
          filterOptions: {
            enabled: true,
            placeholder: "Whome To Meet",
          },
        },
        {
          label: "Refrences",
          field: "refrence",
          filterOptions: {
            enabled: true,
            placeholder: "Refrences",
          },
        },
        {
          label: "Time Out",
          field: "timeout",

          filterOptions: {
            enabled: true,
            placeholder: "Time Out",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "dropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_at",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "dropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_at",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
      canView: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Register") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          this.canView = item.view;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
